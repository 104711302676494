import React, { useEffect, useMemo, useState } from 'react';
import * as S from './Countdown.styled';
import { useTranslation } from 'react-i18next';
import useDateTimeFormat from '../../hooks/useDateTimeFormat';
import { eventDate } from '../../dates';

function Countdown() {
    const [countDownValues, setCountDownValues] = useState(null);
    const [showCountdown, updateShowCountdown] = useState(false);
    const { t } = useTranslation();
    const countDownDate = useMemo(() => eventDate.getTime(), []);
    const dateTimeFormatter = useDateTimeFormat();

    const dateFormatter = useMemo(() => {
        return dateTimeFormatter.format(eventDate);
    }, [dateTimeFormatter]);

    useEffect(() => {
        const now = new Date().getTime();
        const timeleft = countDownDate - now;

        // Calculating the days, hours, minutes and seconds left
        const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

        const values = { days, hours, minutes, seconds };

        for (const key in values) {
            if (values[key] < 10) {
                values[key] = `0${values[key]}`;
            }
        }
        setCountDownValues(values);
        updateShowCountdown(countDownDate - now > 0);

        const interval = setInterval(function () {
            const now = new Date().getTime();
            const timeleft = countDownDate - now;

            // Calculating the days, hours, minutes and seconds left
            const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
                (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor(
                (timeleft % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

            const values = { days, hours, minutes, seconds };

            for (const key in values) {
                if (values[key] < 10) {
                    values[key] = `0${values[key]}`;
                }
            }
            setCountDownValues(values);
            updateShowCountdown(countDownDate - now > 0);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [countDownDate]);

    return (
        <S.Countdown>
            {countDownValues && showCountdown && (
                <span>
                    {t('countdown.premieres_on')} <b>{dateFormatter}</b>{' '}
                    {t('countdown.in')}{' '}
                    <b>
                        {countDownValues.days}
                        {t('generic.date_days')} : {countDownValues.hours}
                        {t('generic.date_hours')} : {countDownValues.minutes}
                        {t('generic.date_minutes')} : {countDownValues.seconds}
                        {t('generic.date_seconds')}
                    </b>
                </span>
            )}
        </S.Countdown>
    );
}

export default Countdown;
