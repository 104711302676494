import styled from 'styled-components';

export const Countdown = styled.div`
    color: #fff;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.12);
    font-size: 2rem;
    font-family: 'Fira Sans', sans-serif;
    display: inline-block;
    padding: 8px;
    width: 92%;
`;
