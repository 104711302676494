import React from 'react';
import { Trans } from 'react-i18next';
import * as S from './Quote.styled';

function Quote({ avatar, quote, author, className }) {
    return (
        <S.Quote className={className}>
            <S.Image src={avatar} />
            <S.Content>
                <S.Text>
                    <Trans i18nKey={quote} />
                </S.Text>
                <S.Author>{author}</S.Author>
            </S.Content>
        </S.Quote>
    );
}

export default Quote;
