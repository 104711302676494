import styled from "styled-components";

const Button = styled.a`
  display: inline-block;
  background: ${props => props.colors ? props.colors.main : props.theme.main.primaryColor};

  text-decoration: none;
  color: #fff;
  text-align: center;

  transition: 0.2s ease-in-out;
  text-transform: uppercase;
  font-family: 'Fira Sans', sans-serif;
  font-weight: 700;
  letter-spacing: 0.1rem;

  font-size: 1.4rem;
  border-radius: 60px;
  height: 42px;
  line-height: 42px;
  padding: 0 20px;
  min-width: 150px;

  &:hover {
    background: ${props => props.colors ? props.colors.hover : props.theme.main.primaryColorHover};
  }
`;

export default Button;
