import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as S from './ContentSection.styled';
import Quote from '../Quote';
import transComponents from '../../style/transComponents';
import { renderContentSectionBlock } from '../ContentSectionBlock';
import avatar from '../../assets/img/CloseupPicturequote.webp';

function ContentSection() {
    const [isMobileSize] = useState(
        window.matchMedia('(max-width: 768px)').matches
    );
    const [practicalIsExpanded, setPracticalIsExpanded] = useState(
        !isMobileSize
    );
    const [aboutIsExpanded, setAboutIsExpanded] = useState(!isMobileSize);
    const { t } = useTranslation();

    const aboutBlock = useMemo(() => {
        return (
            <>
                {aboutIsExpanded && (
                    <Quote
                        author={t('quote_author')}
                        quote={t('quote')}
                        avatar={avatar}
                    />
                )}
            </>
        );
    }, [aboutIsExpanded, t]);

    const practicalInfoBlocks = useMemo(() => {
        const translations = t('practical_info.blocks', {
            returnObjects: true,
            count: 1,
            components: transComponents,
        });
        if (!practicalIsExpanded) translations.length = 2;
        return translations;
    }, [practicalIsExpanded, t]);

    return (
        <S.ContentSection>
            <S.ContentSectionWrapper>
                <S.MobileReverseContentPart mobile={2} desktop={1}>
                    <S.ContentBlock>
                        <S.ContentTitle>{t('block1.title')}</S.ContentTitle>
                        <S.ContentText>
                            {renderContentSectionBlock(t, 'block1.blocks')}
                        </S.ContentText>
                    </S.ContentBlock>
                    {aboutBlock}

                    {aboutIsExpanded ? null : (
                        <S.ActionButton
                            onClick={() => setAboutIsExpanded(true)}
                            disabled={false}
                        >
                            {t('generic.read_more')}
                        </S.ActionButton>
                    )}
                </S.MobileReverseContentPart>
                <S.MobileReverseContentPart mobile={1} desktop={2}>
                    <S.ContentBlock>
                        <S.ContentTitle>
                            {t('practical_info.title')}
                        </S.ContentTitle>
                        <S.ContentText>
                            <ul>
                                {practicalInfoBlocks.map((item, i) => (
                                    <li key={`practical_info-block-${i}`}>
                                        <Trans
                                            i18nKey={item}
                                            components={transComponents}
                                        />
                                    </li>
                                ))}
                            </ul>
                            {practicalIsExpanded ? null : (
                                <S.ActionButton
                                    onClick={() => setPracticalIsExpanded(true)}
                                    disabled={false}
                                >
                                    {t('generic.read_more')}
                                </S.ActionButton>
                            )}

                            <div style={{ position: 'relative',  paddingTop: '56.25%'}}>
                                <iframe 
                                    src="https://iframe.mediadelivery.net/embed/192370/8e682c28-afe4-4a54-aa3f-e6a66b3e8754?autoplay=false&loop=false&muted=false&preload=false&responsive=true" 
                                    loading="lazy" 
                                    style={{border: '0', position: 'absolute', top: '0', height: '100%', width: '100%'}} 
                                    allowfullscreen="true"
                                    title="contentPreview">
                                </iframe>
                            </div>
                        </S.ContentText>
                    </S.ContentBlock>
                </S.MobileReverseContentPart>
            </S.ContentSectionWrapper>
        </S.ContentSection>
    );
}

export default ContentSection;
