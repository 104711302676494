import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const enabledLanguages = ['nl'];

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init(
        (() => {
            let fallbackLng = enabledLanguages.indexOf('en') > -1 ? 'en' : 'nl';
            if (navigator && navigator.language) {
                const lang = navigator.language;
                enabledLanguages.forEach((enabledLanguages) => {
                    if (lang.indexOf(enabledLanguages) > -1)
                        fallbackLng = enabledLanguages;
                });
            }

            const resources = {};
            enabledLanguages.forEach((language) => {
                resources[language] = {
                    translation: require(`../lang/locales/${language}/translation.js`),
                };
            });

            return {
                resources,
                lng: window.localStorage.getItem('lang') || fallbackLng,
                fallbackLng,
                interpolation: {
                    escapeValue: false,
                },
                react: {
                    // transWrapTextNodes: 'span'
                    transKeepBasicHtmlNodesFor: [
                        'br',
                        'strong',
                        'i',
                        'span',
                        'ol',
                        'li',
                        'ul',
                        'p',
                    ],
                },
            };
        })()
    );

export default i18n;
