import {useTranslation} from "react-i18next";
import {useMemo} from "react";

export default function useDateTimeFormat(input) {
    const {i18n} = useTranslation();

    return useMemo(() => {
        const options = input || {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            }
        ;
        let locale;

        switch (i18n.language) {
            case 'en':
                locale = 'en-US';
                break;
            case 'nl':
            default:
                locale = 'nl-NL';
                break;
        }
        return new Intl.DateTimeFormat(locale, options);
    }, [input, i18n.language])
}
