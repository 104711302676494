import React, { useCallback, useMemo } from 'react';
import * as S from './Header.styled';
import Logo from '../../assets/img/pause-logo.png';
import { useTranslation } from 'react-i18next';
import { enabledLanguages } from '../../lang/i18n';

function Header() {
    const { i18n } = useTranslation();
    const language = i18n.language;

    const changeLang = useCallback(
        (lang) => {
            return () => {
                i18n.changeLanguage(lang);
                window.localStorage.setItem('lang', lang);
            };
        },
        [i18n]
    );

    const langButtons = useMemo(() => {
        return enabledLanguages.map((enabledLanguage) => {
            return (
                <S.LangMenuItem key={`pick-language-button-${enabledLanguage}`}>
                    <S.Button
                        isActive={language === enabledLanguage}
                        onClick={changeLang(enabledLanguage)}
                    >
                        {enabledLanguage.toUpperCase()}
                    </S.Button>
                </S.LangMenuItem>
            );
        });
    }, [changeLang, language]);

    return (
        <S.Header>
            <S.Wrapper>
                <S.LangMenu style={{ display: 'none' }}>
                    {langButtons.length > 1 ? langButtons : null}
                </S.LangMenu>
                <S.Logo to="/">
                    <img src={Logo} alt="logo" />
                </S.Logo>
            </S.Wrapper>
        </S.Header>
    );
}

export default Header;
