import styled from 'styled-components';
import pauseIcon from '../../assets/img/pause-titel@2x.png';
import QuoteComponent from '../Quote';
import { ContentPart as CP } from '../ContentSection/ContentSection.styled';
import {
    BaseContentTitle,
    BaseContentWrapper,
    BaseSectionTitle,
} from '../../style/shared/text.styled';

export const ContentSection = styled.div`
    color: #fff;
`;

export const ActionButton = styled.button`
    display: inline-block;
    background: ${(props) =>
        props.colors ? props.colors.main : props.theme.main.primaryColor};

    text-decoration: none;
    color: #fff;
    text-align: center;

    transition: 0.2s ease-in-out;
    text-transform: uppercase;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 700;
    letter-spacing: 0.1rem;

    font-size: 1.4rem;
    border-radius: 60px;
    height: 42px;
    line-height: 42px;
    padding: 0 20px;
    min-width: 150px;
    width: 100%;
    margin-bottom: 3rem;

    &:hover {
        background: ${(props) =>
            props.colors
                ? props.colors.hover
                : props.theme.main.primaryColorHover};
    }
`;

export const ContentPart = styled.div`
    //flex: 50% 0 0;
    display: flex;
    gap: 5rem;
    flex-grow: 1;
    flex-wrap: wrap;

    @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
        //padding: 0 1rem;
        flex-direction: column;
        gap: unset;
    }
`;

export const ContentSectionWrapper = styled(CP)`
    padding-top: 5rem;
    padding-bottom: 2rem;

    ${(props) => props.theme.wrapper}
    display: flex;
    flex-direction: column;
`;

export const MobileReverseContentPart = styled(ContentPart)`
    ${(props) => {
        let css = `
        ${
            props.desktop &&
            `
            order: ${props.desktop};
        `
        }
        ${
            props.mobile &&
            `
            @media screen and (max-width: ${props.theme.layout.bpM}) {
             order: ${props.mobile};
            }
        `
        }
    `;
        return css;
    }}
`;

export const ContentBlock = styled(BaseContentWrapper)`
    max-width: calc(33% - 5rem);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto 1fr;

    > * {
        width: 100%;
    }

    @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
        margin-bottom: 4rem;
        max-width: 100%;
    }
`;

export const ContentTitle = styled(BaseSectionTitle)`
    letter-spacing: 0.5rem;
    text-transform: uppercase;
    display: block;
`;

export const PreviousEditionTitle = styled(BaseContentTitle)`
    margin-bottom: 1rem;
    display: block;
    position: relative;
    align-self: flex-start;

    // @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
    //   font-size: 1.5rem;
    // }

    &:before {
        content: '';
        width: 6px;
        height: 9px;
        position: absolute;
        background-image: url(${pauseIcon});
        background-size: 100%;
        bottom: 25%;
        left: -15px;
    }
`;

export const ContentText = styled.div`
    line-height: 2.4rem;
    padding-bottom: 5rem;

    a {
        text-decoration: none;
        color: ${(props) => props.theme.main.secondaryColor};
        font-weight: 800;
        transition: 0.2s ease-in-out;

        &:hover {
            color: ${(props) => props.theme.main.primaryColor};
        }
    }

    ul {
        margin-bottom: 40px;
    }

    li {
        padding-left: 24px;
        position: relative;
        margin-bottom: 14px;

        &:last-child {
            margin-bottom: 0;
        }

        &:before {
            content: '';
            width: 8px;
            height: 8px;
            top: 8px;
            left: 0px;
            border-radius: 50%;
            background-color: ${(props) => props.theme.main.secondaryColor};
            position: absolute;
        }

        ${(props) =>
            props.pause &&
            `
            padding-left: 0;
            &:before {
                content: none;
            }
            &:after {
                content: "";
                width: 6px;
                height: 9px;
                position: absolute;
                background-image: url(${pauseIcon});
                background-size: 100%;
                background-color: transparent;
                top: 8px;
                left: -15px;
            }        
        `}
    }
`;

export const VideoAndQuote = styled.div`
    align-self: end;
`;

export const Quote = styled(QuoteComponent)`
    align-self: flex-start;
    margin-top: 5rem;
`;
