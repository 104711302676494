import styled from "styled-components";
import {BottomButtons} from "../ContentSectionLight/ContentSectionLight.styled";

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
  padding: 2rem;

  @media screen and (max-width: ${props => props.theme.layout.bpM}) {
    font-size: 1rem;
    padding: 2rem 1rem;
  }
`;

export const Copyright = styled.div`
  @media screen and (max-width: ${props => props.theme.layout.bpM}) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  //width: 50vw;
  gap: 2.5rem;
  box-sizing: unset;

  @media screen and (max-width: ${props => props.theme.layout.bpM}) {
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1.5rem;
    justify-content: center;

  }

  > * {
    display: flex;
    align-items: center;
  }

  > *:after {
    content: '◉';
    color: ${props => props.theme.main.secondaryColor};;
    font-size: 1.25rem;
    padding-left: 2.5rem;
    display: flex;
    height: 100%;
    align-items: center;
    @media screen and (max-width: ${props => props.theme.layout.bpM}) {
      padding-left: 1.5rem;
    }
  }

  @media screen and (max-width: ${props => props.theme.layout.bpM}) {
    > *:first-child:after {
      content: unset;
    }

  }

  > *:last-child:after {
    content: unset;
  }
`

export const Link = styled.div`
  color: white;
  //text-decoration: none;
`
export const SocialButtonGroup = styled(BottomButtons)`
  gap: 2rem;
  font-size: 2.5rem;
  @media screen and (max-width: ${props => props.theme.layout.bpM}) {
    font-size: 1.75rem;
    gap: 1rem;
  }
`;

export const SocialButton = styled('a')`
  text-decoration: none;
  color: white;
`;
