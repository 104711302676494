import React, { useEffect, useState } from 'react';
import * as S from './TicketArea.styled';
import BuyTicket from '../BuyTicket';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { checkTicketDate } from '../../dates';

function TicketArea() {
    const { t } = useTranslation();
    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(function () {
            setCurrentDate(new Date());
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <S.TicketArea>
            <BuyTicket />
            <S.TicketSection>
                <S.TicketBlockTitle>{t('already_ticket')}</S.TicketBlockTitle>
                <S.ActionButton
                    disabled={checkTicketDate > currentDate}
                    as={Link}
                    to="/pause-universe"
                >
                    {t('enter_pause_universe')}
                </S.ActionButton>
                {checkTicketDate > currentDate && (
                    <S.TicketBlockContent>
                        {t('available_soon')}
                    </S.TicketBlockContent>
                )}
            </S.TicketSection>
        </S.TicketArea>
    );
}

export default TicketArea;
