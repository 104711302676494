import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './PreviousEditions.styled';
import { ContentSectionWrapper } from './PreviousEditions.styled';
import transComponents from '../../style/transComponents';
import { renderContentSectionBlock } from '../ContentSectionBlock';

function PreviousEditions() {
    const { t } = useTranslation();

    const previousEditions = useMemo(() => {
        const translations = t('previous_editions.editions', {
            returnObjects: true,
            count: 1,
            components: transComponents,
        });

        return translations.map((edition, i) => (
            <S.ContentBlock key={`previous-edition-${edition.title}`}>
                <S.PreviousEditionTitle>{edition.title}</S.PreviousEditionTitle>
                <S.ContentText>
                    {renderContentSectionBlock(t, edition.blocks)}
                </S.ContentText>
                <S.VideoAndQuote>
                    <div style={{ position: 'relative',  paddingTop: '56.25%'}}>
                        <iframe 
                            src={"https://iframe.mediadelivery.net/embed/192370/" + edition.video.file + "?autoplay=false&loop=false&muted=false&preload=false&responsive=true"}
                            loading="lazy" 
                            style={{border: '0', position: 'absolute', top: '0', height: '100%', width: '100%'}} 
                            allowfullscreen="true"
                            title={`${edition.title.replace(/./g, '-')}-${i}`}>
                        </iframe>
                    </div>
                </S.VideoAndQuote>
                <S.Quote
                    avatar={`assets/img/${edition.quote.avatar}`}
                    quote={edition.quote.text}
                    author={edition.quote.author}
                />
            </S.ContentBlock>
        ));
    }, [t]);

    return (
        <ContentSectionWrapper>
            <S.ContentSection id="previous-talks">
                <S.ContentSectionWrapper>
                    <S.ContentTitle>
                        {t('previous_editions.title')}
                    </S.ContentTitle>
                    <S.ContentPart>{previousEditions}</S.ContentPart>
                </S.ContentSectionWrapper>
            </S.ContentSection>
        </ContentSectionWrapper>
    );
}

export default PreviousEditions;
