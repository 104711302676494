import {Loading, LoadingContent} from "./LoadingOverlay.styled";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function LoadingOverlay() {
    return (
        <Loading>
            <LoadingContent>
                <FontAwesomeIcon spin icon={faCircleNotch}/>
            </LoadingContent>
        </Loading>
    )
}

export default LoadingOverlay;
