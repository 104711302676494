import React from 'react';
import * as S from './HeroHeader.styled';

function HeroHeader({children, home = false, className}) {
    return (
        <S.HeroHeader className={className} home={home}>
            {children}
        </S.HeroHeader>
    );
}

export default HeroHeader;
