import styled from 'styled-components';

export const Transition = styled.div`
  height: 180px;
  @media screen and (max-width: ${props => props.theme.layout.bpM}) {
    height: 100px;
  }
  width: 100%;

  > svg {
    fill: #fff;
    height: 100%;
    width: 100%;
  }
`;
