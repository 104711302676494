const main = {
    primaryColor: '#4B48D6',
    primaryColorHover: '#3734b9',
    secondaryColor: '#9795FF',
    secondaryColorHover: '#9795FF',
    ctaColor: '#E94D44',
    ctaColorHover: '#c13830',
    textColor: '#585857',
};

const layout = {
    gap: '20px',
    bpS: '480px',
    bpM: '820px',
    bpL: '1024px',
    widthM: '900px',
    widthL: '1200px',
};

const wrapper = () => `
    margin: 0 auto;
    max-width: ${layout.widthL};
    width: 100%;
    padding-left: ${layout.gap};
    padding-right: ${layout.gap};
    @media screen and (max-width: ${layout.bpL}) {
        max-width: ${layout.widthL};
    }
 `;

const mixins = {
    wrapper: wrapper,
};

const theme = {
    main,
    layout,
    wrapper,
    mixins,
};

export default theme;
