import styled from 'styled-components';
import Button from '../../style/shared/button.styled';

export const TicketSection = styled.div`
    width: 320px;
    @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
        width: 100%;
        margin-bottom: 30px;
        &:last-child {
            margin: 0;
        }
    }
`;

export const TicketBlockTitle = styled.div`
    text-transform: uppercase;
    color: #fff;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 800;
    font-size: 1.6rem;
    letter-spacing: 0.5rem;
    margin-bottom: 20px;
`;

export const ActionButton = styled(Button)`
    width: 100%;
    font-size: 1.8rem;
    border-radius: 60px;
    height: 58px;
    line-height: 58px;
    margin-bottom: 20px;

    @media screen and (max-width: 350px) {
        font-size: 1.5rem;
    }

    ${(props) =>
        props.disabled &&
        `
        pointer-events: none;
        cursor: default;
        background-color: #323160;
        color: rgba(246, 246, 249, 0.4);
    `}
`;

export const TicketBlockContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: 'Fira Sans', sans-serif;
    font-weight: bold;
    letter-spacing: 0.1rem;

    ${(props) => props.hide && `display: none;`}
    > a {
        color: #b4b3f6;
        text-decoration: none;
        padding: 0 6px;
        transition: 0.2s ease-in-out;
        font-weight: 700;
        font-family: 'Fira Sans', sans-serif;
        letter-spacing: 0.1em;

        &:hover {
            color: ${(props) => props.theme.main.primaryColor};
        }
    }

    > div {
        margin: 0 8px;
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: ${(props) => props.theme.main.secondaryColor};
    }
`;
