import styled from 'styled-components';
import HeroHeader from "../HeroHeader/HeroHeader";

export const Loading = styled(HeroHeader)`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  bottom: 0;

  @media screen and (max-width: ${props => props.theme.layout.bpM}) {
    background-position: top;
  }
`;

export const LoadingContent = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255, 0.75);

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
`;
