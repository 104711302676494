import styled from 'styled-components';

export const HeroContent = styled.div`
    max-width: 795px;
    width: 100%;
    padding: 1rem 20px 100px;
    margin: 0 auto;
    text-align: center;
    @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
        padding: 40px 20px;
    }
`;

export const PreTitle = styled.span`
    color: #fff;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 400;
    font-size: 2.4rem;
    margin-top: 2%;
    margin-bottom: 0rem;
    display: inline-block;
`;

export const Title = styled.h1`
    font-weight: unset;
    font-size: 8.8rem;
    line-height: 4rem;
    margin-top: 27%;
    margin-bottom: 2rem;
    @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
        font-size: 5rem;
        margin-top: 0rem;
    }
`;

export const Sub = styled(PreTitle)`
    font-size: 2.4rem;
    @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
        font-size: 2rem;
        font-weight: normal;
        max-width: 75vw;
    }
`;

export const Author = styled.span`
    display: block;
    margin-bottom: 100px;
    font-family: 'Fira Sans', sans-serif;
    font-size: 3.5rem;
    color: #fff;
    @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
        font-size: 2.4rem;
        margin-bottom: 40px;
    }
`;
