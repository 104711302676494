import styled from 'styled-components';
import { TicketBlockTitle as TBT } from '../../style/shared/ticketSection.styled';
export {
    ActionButton,
    TicketSection,
} from '../../style/shared/ticketSection.styled';

export const SvgContainer = styled.div`
    height: 180px;
    @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
        height: 100px;
    }
    width: 100%;
`;

export const SvgLine = styled.svg`
    background: #fff;
    height: 180px;
    width: 100%;
    stroke: rgb(0, 0, 0);
    stroke-width: 0.15px;
    @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
        height: 100px;
        stroke-width: 0.5px;
    }
`;

export const BottomSection = styled.div`
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 20px 0 20px;
    @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
        padding: 50px 20px 50px 20px;
    }
`;

export const PauseTitle = styled.div`
    text-align: center;
    margin-bottom: 20px;
`;

export const TicketBlockTitle = styled(TBT)`
    text-align: center;
    color: #000;
`;
