import styled from "styled-components";
import theme from "./theme";
import {Link} from "react-router-dom";

const PrimarySpan = styled.span`
  color: ${theme.main.primaryColor};

  &:hover {
    color: ${theme.main.primaryColorHover};
  }
`
const SecondarySpan = styled.span`
  color: ${theme.main.secondaryColor};

  &:hover {
    color: ${theme.main.secondaryColorHover};
  }
`

const Anchor = styled.a`
  text-decoration: none;
  color: ${props => props.theme.main.secondaryColor};
  font-weight: 800;
  transition: 0.2s ease-in-out;

  &:hover {
    color: ${props => props.theme.main.primaryColor};
  }
`

const UlNoMargin = styled.ul`
  margin: 0 !important;
`

const DottedSpacer = styled.div`
  width: 100%;
  margin-bottom: -2rem;
  border-bottom: 1px dashed ${(props) => props.theme.main.secondaryColor};
  min-height: 1px;
`;

const transComponents = {
    primary: <PrimarySpan as={'span'}/>,
    secondary: <SecondarySpan as={'span'}/>,
    anchor: <Anchor as={'a'}/>,
    routerLink: <Anchor as={Link}/>,
    ulNoMargin: <UlNoMargin as={'ul'}/>,
    dottedSpacer: <DottedSpacer as={'div'}/>,
}

export default transComponents;
