import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Header = styled.div`
    padding-top: 20px;
    @media screen and (max-width: ${(props) => props.theme.layout.bpL}) {
        padding-top: 10px;
    }
`;

export const Logo = styled(Link)`
  display: block;
  max-width: 150px;
  width: fit-content;
  margin: 0 auto;

  @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
    margin-bottom: -3rem; 
    max-width: 50vw;

    img {
      width: 100px;
    }
  }
}
`;

export const Wrapper = styled.div`
    ${(props) => props.theme.wrapper}
    position: relative;
`;

export const LangMenu = styled.ul`
    position: absolute;
    top: 0;
    right: 20px;
    font-family: 'Fira Sans', sans-serif;

    @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
        position: static;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
`;

export const LangMenuItem = styled.li`
    display: inline-block;
    padding-right: 20px;
    position: relative;

    &:after {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: ${(props) => props.theme.main.primaryColor};
        position: absolute;
        right: 8px;
        top: 7px;
    }

    &:last-child {
        padding-right: 0;

        &:after {
            content: none;
        }
    }
`;

export const Button = styled.button`
    text-decoration: none;
    color: #fff;
    font-weight: 400;
    font-size: 1.8rem;
    display: inline-block;
    position: relative;
    padding: 0 0 4px 0;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        background: #fff;
        transition: 0.2s ease-in-out;
        opacity: 0;
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }

    ${(props) =>
        props.isActive &&
        `
        font-weight: 800;
        &:after {
            opacity: 1;
        }
    `}
`;
