import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './BuyTicket.styled';
import { paylogic, linktree } from '../../links';
import {startSaleDate} from "../../dates";

function BuyTicket() {
    const { t } = useTranslation();
    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(function () {
            setCurrentDate(new Date());
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <S.TicketSection>
            <S.TicketBlockTitle>
                {t('tickets.no_ticket_yet')}
            </S.TicketBlockTitle>
            <S.ActionButton disabled={startSaleDate > currentDate} href={paylogic} target="_blank">
                {t('buy_ticket')}
            </S.ActionButton>
            <S.TicketBlockContent>
                <a href={linktree} target="_blank" without rel="noreferrer">
                    {t('more_info')}
                </a>
            </S.TicketBlockContent>
        </S.TicketSection>
    );
}

export default BuyTicket;
