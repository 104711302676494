import styled from 'styled-components';
import { BaseContentWrapper } from '../../style/shared/text.styled';

export const Quote = styled(BaseContentWrapper)`
    display: flex;
    margin-bottom: 40px;
`;

export const Image = styled.img`
    align-self: flex-start;
    flex: 0 0 auto;
    width: 70px;
    height: 70px;
    margin-right: 2.5rem;
    border-radius: 50%;
`;

export const Content = styled.div``;

export const Text = styled.blockquote``;

export const Author = styled.div`
    padding-left: 2.5rem;

    &:before {
        content: '―';
        padding-right: 0.5rem;
    }

    font-size: 1.6rem;

    @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
        padding-left: 5rem;
    }
`;
