import styled from "styled-components";
import {ContentSectionWrapper as Wrapper, ContentText as Text} from "../ContentSection/ContentSection.styled";
import Btn from "../../style/shared/button.styled";
import {BaseContentWrapper, BaseSectionTitle} from "../../style/shared/text.styled";

export {
    ContentBlock, ContentPart
} from "../ContentSection/ContentSection.styled";

export const ContentSectionWrapper = styled(Wrapper)`
  flex-direction: column;
`

export const ContentTitle = styled(BaseSectionTitle)`
  letter-spacing: 0.5rem;
  text-transform: uppercase;
  font-size: 2.5rem;
  margin-bottom: 0;
`;

export const ContentText = styled(Text)`
  font-size: 1.75rem;
`;

export const AboutTalkComponent = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
`

export const AboutTalkInfo = styled.div`
  display: flex;
  width: 100%;
  gap: 5rem;
  justify-content: space-between;
  @media screen and (max-width: ${props => props.theme.layout.bpM}) {
    flex-direction: column;
  }
`;

export const AboutTalkInfoTextContainer = styled.div`
  width: 50%;
  @media screen and (max-width: ${props => props.theme.layout.bpM}) {
    width: 100%;
  }
`

export const AboutTalkVideoContainer = styled.div`
  width: 50%;
  @media screen and (max-width: ${props => props.theme.layout.bpM}) {
    width: 100%;
  }
`

export const AboutTalkButtonsContainer = styled.div`
  margin-top: 5rem;
  width: 100%;
  display: flex;
  gap: 2rem;
`

export const AboutPauseText = styled(ContentText)`
  display: flex;
  flex-direction: row;
  column-gap: 15rem;

  @media screen and (max-width: ${props => props.theme.layout.bpM}) {
    flex-direction: column;
    
    ul {
      margin: 0;
    }
    
    padding-bottom: 4rem;
  }

  li {
    padding-top: .75rem;
    padding-bottom: .75rem;

    strong {
      font-family: 'Fira Sans', sans-serif;
    }

    &::after {
      margin-top: .75rem;
      margin-bottom: .75rem;
    }
  }
`


export const ContentSectionLight = styled(BaseContentWrapper)`
  background: #fff;
  color: #000;
  padding: 50px 0 0 0;
`;

export const Button = styled(Btn)`
  min-width: 100px;

  @media screen and (max-width: ${props => props.theme.layout.bpM}) {
    min-width: 15vw;
    font-size: 1.2rem;
    padding: 0 1.75rem;
    height: 4rem;
    line-height: 4rem;
  }

  ${(props) => props.outline && `
      background: #fff;
      border: 1px solid ${props.theme.main.primaryColor};
      color: ${props.theme.main.primaryColor};
    
      &:hover {
        color: #fff;
      }
  `}
`

export const BottomButtons = styled('div')`
  display: flex;
  align-items: center;
  gap: 2rem;
  @media screen and (max-width: ${props => props.theme.layout.bpM}) {
    gap: 3rem;
    flex-wrap: wrap;
  }
`;

export const LinkButtonGroup = styled(BottomButtons)`
  gap: 1rem;
  font-size: 2.5rem;
  @media screen and (max-width: ${props => props.theme.layout.bpM}) {
    width: 100%;
    justify-content: space-between;
    font-size: 2rem;
    flex-wrap: unset;
  }
`;

export const SocialButtonGroup = styled(BottomButtons)`
  gap: 1rem;
  font-size: 2.5rem;
  @media screen and (max-width: ${props => props.theme.layout.bpM}) {
    width: 100%;
    justify-content: center;
    font-size: 3rem;
    gap: 7.5rem;
  }
`;

export const SocialButton = styled('a')`
  text-decoration: none;
  color: black;
`;
