import styled from "styled-components";
import pauseIcon from '../../assets/img/pause-titel@2x.png';

export const PauseIconBefore = `
    position: relative;

    &:before {
        content: "";
        width: 6px;
        height: 9px;
        position: absolute;
        background-image: url(${pauseIcon});
        background-size: 100%;
        top: .7rem;
        left: -15px;
    }
 `

export const DisablePauseBeforeIcon = `
 &:before {
    content: unset;
 }
`


export const BaseSectionTitle = styled.h2`
  font-family: 'Fira Sans', sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
  padding-bottom: 2rem;

  ${PauseIconBefore}
  &:before {
    top: 1.2rem;
  }
`

export const BaseContentTitle = styled.h3`
  font-family: 'Fira Sans', sans-serif;

  font-size: 1.8rem;
  font-weight: 700;

  ${PauseIconBefore}
`

export const BaseContentWrapper = styled.div`
  font-size: 1.4rem;

  a {
    text-decoration: none;
    font-weight: 800;
    transition: 0.2s ease-in-out;
  }

  blockquote {
    font-size: 1.6rem;
    line-height: 2rem;
    font-style: italic;
    font-family: 'Merriweather', serif;
    margin: 0 0 14px 0;
    quotes: "“" "”";

    &:before {
      content: open-quote;
    }

    &:after {
      content: close-quote;
    }
  }

  ul {
    margin-bottom: 40px;
  }

  li {
    font-size: 1.4rem;
    padding-left: 24px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: "";
      width: 8px;
      height: 8px;
      top: 8px;
      left: 0;
      border-radius: 50%;
      background-color: ${props => props.theme.main.secondaryColor};
      position: absolute;
    }

    ${props => props.pause && `
        padding-left: 0;
        &:before {
            content: none;
        }
        &:after {
            content: "";
            width: 6px;
            height: 9px;
            position: absolute;
            background-image: url(${pauseIcon});
            background-size: 100%;
            background-color: transparent;
            top: 8px;
            left: -15px;
        }        
    `}
  }
`
