import React from 'react';
import * as S from './Container.styled';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import routes from '../../routes';

function Container() {
    return (
        <S.Container>
            <ToastContainer />
            <Switch>
                {routes.map((route) => {
                    return (
                        <Route
                            path={route.path}
                            component={route.component}
                            key={route.path}
                            exact={route.exact}
                        />
                    );
                })}
                <Redirect from="*" to="/" />
            </Switch>
        </S.Container>
    );
}

export default Container;
