import React from 'react';
import * as S from './PageTransition.styled';

function PageTransition({dark = false}) {
    return (
        <S.Transition>
            {dark ?
                <svg
                    preserveAspectRatio="none"
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <polygon points="0,100 0,0 100,0"/>
                </svg>
                :
                <svg
                    preserveAspectRatio="none"
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <polygon points="0,100 100,100 100,0"/>
                </svg>
            }

        </S.Transition>
    );
}

export default PageTransition;
