import styled from 'styled-components';

export {
    TicketSection,
    ActionButton,
    TicketBlockContent,
    TicketBlockTitle,
} from '../../style/shared/ticketSection.styled';

export const TicketArea = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    padding-top: 60px;
    @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
        margin-top: 8rem;
        flex-direction: column;

        > :nth-child(2) {
            margin-top: 2rem !important;
        }
    }
`;
