import transComponents from '../../style/transComponents';
import ContentSectionBlock from './ContentSectionBlock';
import React from 'react';

function renderContentSectionBlock(
    t,
    key,
    options = {returnObjects: true, components: transComponents}
) {
    if (Array.isArray(key)) {
        return key.map((item, i, arr) => (
            <ContentSectionBlock key={i} {...{item, index: i, list: arr}} />
        ))
    }

    return t(key, options).map((item, i, arr) => (
        <ContentSectionBlock key={i} {...{item, index: i, list: arr}} />
    ));
}

export default renderContentSectionBlock;
