import styled from 'styled-components';
import BGHome from '../../assets/img/CoverDirkDeWachter.png';
import BG from '../../assets/img/CoverDirkDeWachter.png';
import BGHomeMobile from '../../assets/img/CoverMobile.webp';

export const HeroHeader = styled.div`
    background-image: url(${(props) => (props.home ? BGHome : BG)});
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    min-height: 100vh;
    @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
        background-image: url(${(props) => (props.home ? BGHomeMobile : BG)});
        min-height: ${(props) => (props.home ? '0' : '100vh')};
        background-position: center;
    }
`;
