import React from 'react';
import Home from './pages/Home';

const FAQ = React.lazy(() => import('./pages/FAQ'));
const Event = React.lazy(() => import('./pages/Event'));
const PauseUniverse = React.lazy(() => import('./pages/PauseUniverse'));

const routes = [
    {
        path: '/',
        component: Home,
        exact: true,
        title: 'Home',
    },
    {
        path: '/faq',
        component: FAQ,
        exact: true,
        title: 'FAQ',
    },
    {
        path: '/event',
        component: Event,
        exact: true,
        title: 'Event',
    },
    {
        path: '/pause-universe',
        component: PauseUniverse,
        exact: true,
        title: 'Pause Universe',
    },
];

export default routes;
