import React from 'react';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Container from './components/Container/';
import LoadingOverlay from './components/LoadingOverlay';
import './lang/i18n';
import GlobalStyle from './style/GlobalStyle';
import './style/fontawesome';
import theme from './style/theme';

const tagManagerArgs = {
    gtmId: 'G-DETVKZ2DN9',
};

if (process.env.NODE_ENV === 'production') {
    TagManager.initialize(tagManagerArgs);
}

function App() {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <React.Suspense fallback={<LoadingOverlay />}>
                <Router>
                    <Container />
                </Router>
            </React.Suspense>
        </ThemeProvider>
    );
}

export default App;
