import {Trans} from "react-i18next";
import transComponents from "../../style/transComponents";
import React from "react";


const ContentSectionBlock = ({item, index = 0, list = []}) => {
    return (
        <>
            <Trans i18nKey={item} components={transComponents}/>
            {index < list.length - 1 && <>
                <br/>
                <br/>
            </>}
        </>
    )
}

export default ContentSectionBlock
