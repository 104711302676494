import Echo from 'laravel-echo';

require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'dev-key',
    wsHost: 'pws.socket.livecast.stream',
    forceTLs: true,
});

export const checkCoupon = (code, eventName, kickRoute = '/') => {
    // testing

    /* return new Promise((resolve) => {
        resolve({ status: 200, ok: true });
    });*/
    return fetch(`/api/use-coupon/handler?coupon=${code}&event=${eventName}`, {
        headers: {
            'X-Socket-Id': window.Echo.socketId(),
            accept: 'application/json',
        },
    }).then((response) => {
        if (response.ok) {
            listenForCodeUsage(eventName, code, kickRoute);
        }
        return response;
    });
};

export const listenForCodeUsage = (eventName, coupon, redirectTo = '/') => {
    const channel =
        `event.${eventName.toLowerCase()}.code.${coupon.toLowerCase()}`.replace(
            ' ',
            ''
        );

    window.Echo.channel(channel).listen('CodeUsed', (data) => {
        if (data.socketId !== window.Echo.socketId()) {
            window.location.href = redirectTo;
            window.localStorage.setItem('coupon', false);
        }
    });
};
