import { createGlobalStyle } from 'styled-components';
import { normalize } from './normalize';
import { miniReset } from './miniReset';
import theme from './theme';

const GlobalStyle = createGlobalStyle`
	${normalize}
	${miniReset}
    
    :root {
      --primary: ${theme.main.primaryColor};
      --primary-hover: ${theme.main.primaryColorHover};
      --secondary: ${theme.main.secondaryColor};
      --secondary-hover: ${theme.main.secondaryColorHover};
      --cta: ${theme.main.ctaColor};
      --cta-hover: ${theme.main.ctaColorHover};
    }
    
	html {
		font-size: 62.5%;
		height: 100%;
	}
	
	body {
		font-family: 'Merriweather', serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-weight: 400;
		font-size: 1.4rem;
		position: relative;
		min-height: 100%;
		color: #585857;
	}
	
	h1 {
		color: #fff;
		font-family: 'Fira Sans', sans-serif;
		font-weight: 800;
		font-size: 5.6rem;
		letter-spacing: 0.2rem;
		text-align: center;
		margin-bottom: 30px;
		@media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
			font-size: 3.4rem;
		}	
	}

	.emojiPanel
	{
		position: absolute;
		bottom: 60px;
		right: calc(50% - 175px);
		background-color: #fff;
		box-shadow: 0 4px 4px 0 rgba(0,0,0,.06);
		width: 350px;
	  }
	  .emojiPanel__heading{
		background-color: #f0f0f0;
		display: flex;
		justify-content: space-between;
	  }
	  .emojiPanel__body{
		max-height: 300px;
		overflow: auto;
	  }
	  .emojiPanel__btn{
		background: none;
		border: none;
		cursor: pointer;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		transition: all 0.2s;
	  }
	  .emojiPanel__btn:hover{
		transform: scale(1.2,1.2);
	  }

	  .chatTextInput {
		display: inline-flex;
		width: 185px;
		bottom: 1px;
		padding: 6px;
		min-height: 57px;
		background: #fff;
		color: rgb(0, 0, 0);
		font-family: 'Open Sans', Arial, sans-serif;
		font-size: 1em;
		text-align: left;
		line-height: 1.2em;
		overflow-x: auto;
	  }

	  .chatTextInput div {
		display:inline-block;
	  }
	
	.jw-title {
		display: none !important;
	}
`;

export default GlobalStyle;
