import styled from 'styled-components';
//import pauseIcon from '../../assets/img/pause-titel@2x.png';
import {
    BaseContentTitle,
    BaseContentWrapper,
} from '../../style/shared/text.styled';

export const ContentSection = styled(BaseContentWrapper)`
    padding: 50px 0 100px 0;
    color: #fff;
`;
export const ActionButton = styled.button`
    display: inline-block;
    background: ${(props) =>
        props.colors ? props.colors.main : props.theme.main.primaryColor};

    text-decoration: none;
    color: #fff;
    text-align: center;

    transition: 0.2s ease-in-out;
    text-transform: uppercase;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 700;
    letter-spacing: 0.1rem;

    font-size: 1.4rem;
    border-radius: 60px;
    height: 42px;
    line-height: 42px;
    padding: 0 20px;
    min-width: 150px;
    width: 100%;
    margin-bottom: 3rem;

    &:hover {
        background: ${(props) =>
            props.colors
                ? props.colors.hover
                : props.theme.main.primaryColorHover};
    }
`;
export const ContentSectionWrapper = styled.div`
    ${(props) => props.theme.wrapper}
    display: flex;
    @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
        flex-direction: column;
    }
`;

export const ContentPart = styled.div`
    flex: 50% 0 0;
    padding: 0 5rem;
    flex-grow: 1;
    @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
        padding: 0 1rem;
    }
`;

export const MobileReverseContentPart = styled(ContentPart)`
    ${(props) => {
        let css = `
        ${
            props.desktop &&
            `
            order: ${props.desktop};
        `
        }
        ${
            props.mobile &&
            `
            @media screen and (max-width: ${props.theme.layout.bpM}) {
             order: ${props.mobile};
            }
        `
        }
    `;
        return css;
    }}
`;

export const ContentBlock = styled.div`
    margin-bottom: 40px;
`;

export const ContentTitle = styled(BaseContentTitle)`
    letter-spacing: 0.5rem;
    text-transform: uppercase;
    margin-bottom: 30px;
    display: block;
    position: relative;
`;

export const ContentText = styled(BaseContentWrapper)`
    line-height: 2.4rem;
`;
