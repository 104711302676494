import React from 'react';
import { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as S from './HeroContent.styled';
import TicketArea from '../TicketArea';
import Countdown from '../Countdown';
import { eventDate } from '../../dates';

function HeroContent() {
    const { t } = useTranslation();

    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(function () {
            setCurrentDate(new Date());
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <S.HeroContent>
            <S.PreTitle>{t('top.pre_title')}</S.PreTitle>
            <S.Title>
                <Trans i18nKey="top.title" />
            </S.Title>
            <S.Sub>
                <Trans i18nKey="top.subtitle" />
            </S.Sub>
            <TicketArea />
            {eventDate > currentDate && <Countdown />}
        </S.HeroContent>
    );
}

export default HeroContent;
